import { type SelectMixedOption } from 'naive-ui/es/select/src/interface';

interface UserManagementState {
  selectedUser: SelectMixedOption | null;
  selectedUserGroup: SelectMixedOption | null;
}

/**
 * Store for keeping track of the app's user management states.
 * Used by editors and admins.
 *
 * @example Getting values
 * ```ts
 * const { selectedUserGroup } = useUserManagement();
 * console.log('selectedUserGroup:', selectedUserGroup.value);
 * ```
 * @example Setting values
 * ```ts
 * const { setSelectedUserGroup } = useUserManagement();
 * setSelectedUserGroup({ label: 'Group 1', key: 'group-1' });
 * ```
 * @package fun-academy-campus
 */
export default function useUserManagement() {
  const { $logger } = useNuxtApp();

  const defaultState: UserManagementState = {
    selectedUser: null,
    selectedUserGroup: null,
  };

  const state = useState<UserManagementState>(
    'sidebarStore',
    () => defaultState
  );

  const selectedUser = computed<UserManagementState['selectedUser']>(
    () => state.value.selectedUser
  );

  const selectedUserGroup = computed<UserManagementState['selectedUserGroup']>(
    () => state.value.selectedUserGroup
  );

  function setSelectedUser(value: UserManagementState['selectedUser']): void {
    $logger.info('Selecting user:', value);
    state.value.selectedUser = value ?? null;
  }

  function setSelectedUserGroup(
    value: UserManagementState['selectedUserGroup']
  ): void {
    $logger.info('Selecting user group:', value);
    state.value.selectedUserGroup = value ?? null;
  }

  return {
    selectedUser,
    selectedUserGroup,
    setSelectedUser,
    setSelectedUserGroup,
  };
}
