import { type VNode } from 'vue';
import { NButton, NResult } from 'naive-ui';
import Welcome from '@/assets/images/welcome.svg?component';

export default defineNuxtComponent({
  name: 'ContentIntro',
  props: {
    description: { default: undefined, type: String },
    title: { default: undefined, type: String },
  },
  setup() {
    const localePath = useLocalePath();
    async function handleParentClick(): Promise<void> {
      await navigateTo(localePath({ name: 'parent' }));
    }
    async function handleTrainerClick(): Promise<void> {
      await navigateTo(localePath({ name: 'trainer' }));
    }
    return { handleParentClick, handleTrainerClick, localePath };
  },
  render(): VNode {
    return (
      <NResult
        class="max-w-screen-md text-center"
        description={this.description}
      >
        {{
          default: this.$slots.default,
          icon: () => (
            <Welcome
              aria-label={this.title}
              class="mx-4 my-2 max-h-30 w-full md:max-h-40"
            />
          ),
          footer: () => (
            <div class="m-auto max-w-xs flex justify-around">
              <NButton
                onClick={this.handleParentClick}
                round
                size="large"
                type="tertiary"
              >
                {this.$t('parent')}
              </NButton>
              <NButton
                onClick={this.handleTrainerClick}
                round
                size="large"
                type="primary"
              >
                {this.$t('trainer')}
              </NButton>
            </div>
          ),
        }}
      </NResult>
    );
  },
});
