import payload_plugin_43XVZcU6KP from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt-vuefire@1.0.3_@firebase+app-types@0.9.2_firebase-admin@12.3.0_encoding@0.1.13__firebase-_zhklcbxmwlxiuq6yp7bwr2vfd4/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_zR6mg1B5jd from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VsrWeKjcoz from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DAtUIaUJ4R from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import naive_ui_tKb6Ec2ET9 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/naive-ui.ts";
import payload_client_LEZbsCeH4Q from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sVQwMtCoz5 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vFWrYOqa6y from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_K0FuAwIElV from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/components.plugin.mjs";
import prefetch_client_VMC3oxCEId from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_XDOgYyoRQF from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt-vuefire@1.0.3_@firebase+app-types@0.9.2_firebase-admin@12.3.0_encoding@0.1.13__firebase-_zhklcbxmwlxiuq6yp7bwr2vfd4/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/vuefire-plugin.mjs";
import plugin_oVY3YDqTGg from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/pwa-icons-plugin.ts";
import pwa_client_pVNxvm7s6d from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_magicast@0.3.4_rollup@4.19.2_vite@5.3.5_@types+node@22.0.2_terser@5.31.3_v2y5a7kf544emditlcdozutlly/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import unocss_MzCDxu9LMj from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/unocss.mjs";
import switch_locale_path_ssr_JcBqdxsWg5 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_ss3K6MWRPH from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _logger_vGcu5iraAU from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/_logger.ts";
import analytics_client_ClOs2HRIGm from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/analytics.client.ts";
import vue_query_JC9gnf7CA1 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/vue-query.ts";
export default [
  payload_plugin_43XVZcU6KP,
  revive_payload_client_zR6mg1B5jd,
  unhead_VsrWeKjcoz,
  router_DAtUIaUJ4R,
  naive_ui_tKb6Ec2ET9,
  payload_client_LEZbsCeH4Q,
  navigation_repaint_client_sVQwMtCoz5,
  check_outdated_build_client_vFWrYOqa6y,
  chunk_reload_client_K0FuAwIElV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VMC3oxCEId,
  plugin_client_XDOgYyoRQF,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_oVY3YDqTGg,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_pVNxvm7s6d,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_JcBqdxsWg5,
  i18n_ss3K6MWRPH,
  _logger_vGcu5iraAU,
  analytics_client_ClOs2HRIGm,
  vue_query_JC9gnf7CA1
]