import { type VNode } from 'vue';

export default defineNuxtComponent({
  props: {
    fullSize: {
      default: false,
      type: Boolean,
    },
  },
  name: 'ContentImage',
  async setup() {
    const { currentCourse, currentModule, currentTask } = useContentStore();
    const route = useRoute();

    const alt = computed<null | string | undefined>(() => {
      if (!currentTask.value) return null;
      switch (true) {
        case isTask(String(route.params.id)):
          return currentTask.value.contentImage?.alt;
        case isModule(String(route.params.id)):
          return currentModule.value?.contentImage?.alt;
        case isCourse(String(route.params.id)):
          return currentCourse.value?.contentImage?.alt;
        default:
          return null;
      }
    });

    const src = computed<null | string | undefined>(() => {
      if (!currentTask.value) return null;
      switch (true) {
        case isTask(String(route.params.id)):
          return currentTask.value.contentImage?.src;
        case isModule(String(route.params.id)):
          return currentModule.value?.contentImage?.src;
        case isCourse(String(route.params.id)):
          return currentCourse.value?.contentImage?.src;
        default:
          return null;
      }
    });

    return { alt, src: src.value && (await getFirebaseStorageUrl(src.value)) };
  },
  render(): VNode | null {
    if (!this.src) return null;
    return (
      <figure class="mx-auto my-4 border-1 border-gray-300 rounded-md border-solid p-2 text-center">
        <img
          alt={this.alt ?? ''}
          class={['m-auto h-auto', this.fullSize ? 'w-full' : 'w-36']}
          itemprop="image"
          src={this.src}
        />
      </figure>
    );
  },
});
