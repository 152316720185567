import { type UserGroup } from '@/types';
import {
  NAvatar,
  NDrawer,
  NDrawerContent,
  NList,
  NListItem,
  NSelect,
  NSpace,
  NText,
} from 'naive-ui';
import { type RenderTag } from 'naive-ui/es/_internal/selection/src/interface';
import {
  type SelectBaseOption,
  type SelectMixedOption,
} from 'naive-ui/es/select/src/interface';
import { type VNode } from 'vue';
import IconSignOut from '@/assets/icons/sign-out.svg?component';
import Character from '@/assets/characters/5.svg?component';
import { ClientOnly, NuxtLink } from '#components';

/**
 * Sidebar menu for campus app.
 * @package fun-academy-campus
 */
export default defineNuxtComponent({
  name: 'SidebarMenu',
  setup() {
    const currentUser = useCurrentUser();
    const localePath = useLocalePath();
    const { isAdmin, isEditor, userClaims, userGroups, userRecords } =
      useQueryData();
    const router = useRouter();
    const { active, show } = useSidebarMenu();
    const {
      selectedUser,
      selectedUserGroup,
      setSelectedUser,
      setSelectedUserGroup,
    } = useUserManagement();

    function mapGroupToOptions({ id, name }: UserGroup): SelectMixedOption {
      return { label: name, value: id };
    }

    function renderLabel(option: SelectBaseOption): VNode | undefined {
      const user = userRecords.value?.find((u) => u.uid === option.value);
      if (!user) return;
      return h('div', { class: 'flex items-center w-full' }, [
        h(NAvatar, {
          class: 'min-w-7 min-h-4',
          round: true,
          size: 'small',
          src: user.photoURL,
        }),
        h('div', { class: 'ml-4 pt-2' }, [
          h('div', null, [option.label as string]),
          h(
            NText,
            { depth: 3, tag: 'div' },
            {
              default: () => <small class="text-xs">{user.email}</small>,
            }
          ),
        ]),
      ]);
    }

    function renderTag({
      option,
    }: Parameters<RenderTag>[0]): VNode | undefined {
      const user = userRecords.value?.find((u) => u.uid === option.value);
      if (!user) return;
      return h('div', { class: 'flex items-center' }, [
        h(NAvatar, {
          class: ['flex', 'h-auto', 'w-5', 'mr-3'].join(' '),
          round: true,
          size: 'small',
          src: user.photoURL,
        }),
        option.label! as string,
      ]);
    }

    const defaultUserValue = computed<SelectMixedOption | undefined>(() => {
      const user = userRecords.value?.find(
        (u) => u.uid === selectedUser.value?.uid
      );
      if (!user) return;
      return { label: user.displayName, value: user.uid };
    });

    const userGroupOptions = computed<SelectMixedOption[]>(() =>
      isAdmin.value
        ? (userGroups.value?.map(mapGroupToOptions) ?? [])
        : (userGroups.value
            ?.filter(({ id }) => userClaims.value?.groups?.includes(id))
            .map(mapGroupToOptions) ?? [])
    );

    const userOptions = computed<SelectMixedOption[] | undefined>(
      () =>
        userRecords.value
          ?.filter((u) =>
            u.customClaims?.groups?.includes(
              String(selectedUserGroup.value?.value)
            )
          )
          .map((r) => ({ label: r.displayName, value: r.uid })) ?? undefined
    );

    function handleClose(): void {
      show.value = false;
    }

    function updateSelectedUserGroup(value: string): void {
      const userGroup = userGroupOptions.value?.find((g) => g.value === value);
      setSelectedUser(null);
      setSelectedUserGroup(userGroup ?? null);
    }

    function updateSelectedUser(value: string): void {
      const user = userOptions.value?.find((user) => user.value === value);
      setSelectedUser(user ?? null);
    }

    router.beforeEach(handleClose);

    return {
      active,
      currentUser,
      defaultUserValue,
      isAdmin,
      isEditor,
      localePath,
      renderLabel,
      renderTag,
      selectedUser,
      selectedUserGroup,
      show,
      updateSelectedUser,
      updateSelectedUserGroup,
      userGroupOptions,
      userOptions,
    };
  },
  render(): VNode | null {
    return (
      <NDrawer
        n-drawer
        placement="right"
        style={{ background: 'linear-gradient(to left, #FEFAE1, #fff)' }}
        v-model:show={this.show}
      >
        <NDrawerContent closable title="Menu">
          {{
            header: () => this.$t('menu'),
            default: () => (
              <NList class="w-full bg-transparent">
                <NListItem>
                  <NuxtLink to={this.localePath({ name: 'index' })}>
                    {this.$t('home')}
                  </NuxtLink>
                </NListItem>
                <ClientOnly>
                  {this.currentUser ? (
                    <>
                      {this.isAdmin && (
                        <NListItem>
                          <NuxtLink to={this.localePath({ name: 'admin' })}>
                            {this.$t('admin')}
                          </NuxtLink>
                        </NListItem>
                      )}
                      <NListItem>
                        <NuxtLink to={this.localePath({ name: 'parent' })}>
                          {this.$t('parent')}
                        </NuxtLink>
                      </NListItem>
                      <NListItem>
                        <NuxtLink to={this.localePath({ name: 'trainer' })}>
                          {this.$t('trainer')}
                        </NuxtLink>
                      </NListItem>
                      {this.isEditor && (
                        <>
                          <NListItem>
                            <NSpace vertical>
                              <NSelect
                                clearable
                                defaultValue={
                                  this.selectedUserGroup?.value as string
                                }
                                filterable
                                onUpdate:value={this.updateSelectedUserGroup}
                                options={this.userGroupOptions}
                                placeholder={this.$t('groups')}
                              />
                            </NSpace>
                          </NListItem>
                          <NListItem>
                            <NSpace vertical>
                              <NSelect
                                disabled={!this.selectedUserGroup?.value}
                                clearable
                                defaultValue={
                                  this.selectedUserGroup?.value
                                    ? (this.selectedUser?.value as string)
                                    : null
                                }
                                filterable
                                onUpdate:value={this.updateSelectedUser}
                                options={this.userOptions}
                                placeholder={this.$t('users')}
                                renderLabel={this.renderLabel}
                                renderTag={this.renderTag}
                                value={String(this.selectedUser?.value)}
                              />
                            </NSpace>
                          </NListItem>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <NListItem>
                        <NuxtLink to={this.localePath({ name: 'register' })}>
                          {this.$t('register')}
                        </NuxtLink>
                      </NListItem>
                      <NListItem>
                        <NuxtLink to={this.localePath({ name: 'sign-in' })}>
                          {this.$t('signIn')}
                        </NuxtLink>
                      </NListItem>
                    </>
                  )}
                </ClientOnly>
              </NList>
            ),
            footer: () => (
              <div class="w-full flex flex-col gap-4">
                <ClientOnly>
                  {this.currentUser ? (
                    <>
                      {this.currentUser.displayName && (
                        <p
                          class="m-0 flex items-center gap-5"
                          itemprop="creator"
                          itemscope
                          itemtype="https://schema.org/Person"
                        >
                          {this.currentUser.photoURL && (
                            <span property="image">
                              <NAvatar circle src={this.currentUser.photoURL} />
                            </span>
                          )}
                          <span property="name">
                            {this.currentUser.displayName}
                          </span>
                        </p>
                      )}
                      <NList class="bg-transparent">
                        <NListItem>
                          <NuxtLink
                            class="mx-auto mb-4"
                            to={this.localePath({ name: 'profile' })}
                          >
                            {this.$t('profile')}
                          </NuxtLink>
                        </NListItem>
                        <NListItem>
                          <NuxtLink
                            class="w-full flex items-center justify-between"
                            to={this.localePath({ name: 'sign-out' })}
                          >
                            {this.$t('signOut')}
                            <IconSignOut class="w-6" />
                          </NuxtLink>
                        </NListItem>
                      </NList>
                    </>
                  ) : null}
                </ClientOnly>
                <Character class="mx-auto mb-4 block max-h-26" />
              </div>
            ),
          }}
        </NDrawerContent>
      </NDrawer>
    );
  },
});
