import { LoadingLayer, NuxtLayout, NuxtPage } from '#components';
import {
  NDialogProvider,
  NMessageProvider,
  NNotificationProvider,
} from 'naive-ui';
import { type VNode } from 'vue';
import { type LocationQueryRaw, type RouteLocationPathRaw } from 'vue-router';

export default defineNuxtComponent({
  name: 'App',
  setup() {
    const currentUser = useCurrentUser();
    const auth = useFirebaseAuth();
    const { t } = useI18n();
    const { isLoading, setIsLoading } = useLoadingStore();
    const localePath = useLocalePath();
    const { $logger } = useNuxtApp();
    const {
      isAdmin,
      isEditor,
      suspenseUserClaims,
      suspenseUserGroups,
      suspenseUserRecords,
      suspenseUserRoles,
      userClaims,
      userGroups,
      userRecords,
      userRoles,
    } = useQueryData();
    const route = useRoute();
    const router = useRouter();
    const { loadingBar } = useTheme();

    const skipLoading = computed<boolean>(
      () =>
        Boolean('loading' === String(route.query?.skip)) ||
        String(route.name).includes('sign-out')
    );

    const skipRoutes = ['register', 'sign-in', 'sign-out'];

    const showLoading = computed<boolean>(
      () => isLoading.value && !skipLoading.value
    );

    async function runSuspenses(): Promise<void> {
      loadingBar.start();
      $logger.info('Running suspenses …');
      await suspenseUserClaims();
      await suspenseUserGroups();
      await suspenseUserRoles();
      loadingBar.finish();
    }

    onMounted((): void => {
      $logger.start('Starting app 🚀');
      auth?.onAuthStateChanged((): void => {
        $logger.log('Auth state updated.');
        // Remove `skip=loading` from the URL
        if (skipLoading.value) {
          const query: LocationQueryRaw = {
            ...(router.currentRoute.value.query ?? {}),
          };
          delete query.skip;
          const route: RouteLocationPathRaw = {
            path: router.currentRoute.value.path,
            query,
          };
          $logger.debug('Replacing route with:', route);
          router
            .replace(route)
            .then(() => setIsLoading(false))
            .catch($logger.error);
        } else {
          // Give some extra time to see the loading screen
          setTimeout(() => setIsLoading(false), 2500);
        }
      });
    });

    useHead({
      meta: [{ name: 'copyright', content: t('appCreator') }],
      titleTemplate: (title?: string): string => {
        const appName = t('appName');
        return title && title !== appName ? `${title} - ${appName}` : appName;
      },
    });

    useSeoMeta({
      appleMobileWebAppCapable: 'yes',
      applicationName: t('appName'),
      author: t('appAuthor'),
      creator: t('appCreator'),
      description: t('appDescription'),
      mobileWebAppCapable: 'yes',
      ogImage: '/screenshot.jpg',
      ogImageHeight: '1600',
      ogImageWidth: '1200',
      ogSiteName: t('appName'),
      ogTitle: t('appName'),
      ogType: 'website',
      title: t('appName'),
      twitterCard: 'summary_large_image',
      twitterSite: t('twitterSite'),
    });

    watch(
      currentUser,
      async (user, prevUser): Promise<void> => {
        if (import.meta.server) return;
        const currentPageName = String(route.name).split('_')[0] || '';
        $logger.debug(
          `Skip ${currentPageName} route`,
          skipRoutes.includes(currentPageName)
        );
        if (prevUser && !user && !skipRoutes.includes(currentPageName)) {
          $logger.debug('Session expired. Signing out…', prevUser);
          await navigateTo(
            localePath({
              name: 'sign-out',
              query: {
                ...(route.query ?? {}),
                redirect: route.fullPath,
                skip: 'loading',
              },
            })
          );
        } else if (user?.uid && 'string' === typeof route.query.redirect) {
          $logger.debug('Redirecting:', route.query.redirect);
          await runSuspenses();
          await navigateTo(route.query.redirect);
        } else {
          if (user) await runSuspenses();
          setIsLoading(false);
        }
      },
      { deep: true, immediate: true }
    );

    watch(isAdmin, (value: boolean): void => {
      if (!currentUser.value) return;
      $logger.debug('User is an admin:', value);
      if (value) $logger.debug('All features enabled 🔥');
    });

    watch(isEditor, async (value: boolean): Promise<void> => {
      if (!currentUser.value) return;
      $logger.debug('User is an editor:', value);
      if (value) {
        $logger.debug('Extra sidebar features enabled ✨');
        loadingBar.start();
        $logger.info('Running additional suspenses …');
        await suspenseUserRecords();
        loadingBar.finish();
      }
    });

    watch(userClaims, (value): void => {
      if (import.meta.server ?? !value) return;
      $logger.debug('User claims:', value);
    });

    watch(userGroups, (value): void => {
      if (import.meta.server ?? !value) return;
      $logger.debug('User groups:', value);
    });

    watch(userRecords, (value): void => {
      if (import.meta.server ?? !value) return;
      $logger.debug('User records:', value);
    });

    watch(userRoles, (value): void => {
      if (import.meta.server ?? !value) return;
      $logger.debug('User roles:', value);
    });

    return { showLoading };
  },
  render(): VNode {
    return (
      <NuxtLayout>
        {this.showLoading ? (
          <LoadingLayer />
        ) : (
          <NDialogProvider>
            <NMessageProvider placement="top">
              <NNotificationProvider>
                <NuxtPage />
              </NNotificationProvider>
            </NMessageProvider>
          </NDialogProvider>
        )}
      </NuxtLayout>
    );
  },
});
