import {
  initializeAnalytics,
  isSupported,
  type Analytics,
} from 'firebase/analytics';

export default defineNuxtPlugin(async () => {
  const { $logger } = useNuxtApp();
  const firebaseApp = useFirebaseApp();
  let analytics: Analytics | null = null;

  $logger.info('Loading analytics …');

  if ('production' === process.env.NODE_ENV && (await isSupported())) {
    analytics = initializeAnalytics(firebaseApp);
    $logger.success('Analytics loaded.');
  } else {
    $logger.fail('Analytics not supported.');
  }

  return {
    provide: {
      analytics,
    },
  };
});
