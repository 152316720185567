interface LoadingState {
  isLoading: boolean;
}

/**
 * Store for keeping track of the app's loading states.
 *
 * @example Getting values
 * ```ts
 * const { isLoading } = useLoadingStore();
 * console.log('isLoading:', isLoading.value);
 * ```
 * @example Setting values
 * ```ts
 * const { setLoading } = useLoadingStore();
 * setLoading(false);
 * ```
 * @package fun-academy-campus
 */
export default function useLoadingStore() {
  const { $logger } = useNuxtApp();

  const defaultState: LoadingState = {
    isLoading: true,
  };

  const state = useState<LoadingState>('loading', () => defaultState);

  const isLoading = computed<LoadingState['isLoading']>(
    () => state.value.isLoading
  );

  function setIsLoading(value: boolean): void {
    $logger.info('Setting isLoading:', value);
    state.value.isLoading = value;
  }

  return { isLoading, setIsLoading };
}
