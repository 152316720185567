import Characters from '@/assets/characters/2.svg?component';
import { getFileNameFromUrl, getFirebaseStorageUrl, isTextTask } from '@/utils';
import { NButton, NModal } from 'naive-ui';
import { type VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentTextAttachment',
  async setup() {
    const { currentModule, currentTask } = useContentStore();
    const { logEvent } = useFirebase();

    const showModal = ref<boolean>(false);

    const filename = getFileNameFromUrl(currentTask.value?.textAttachmentUrl);
    const src = await getFirebaseStorageUrl(
      currentTask.value?.textAttachmentUrl
    );

    /** @todo Trigger modal if file is missing. */
    function handleClick(event: Event): void {
      event.preventDefault();
      if (!filename || !src) {
        showModal.value = true;
        logEvent({
          data: {
            module_id: currentModule.value?.slug,
            task_id: currentTask.value?.slug,
          },
          eventName: 'pdf_missing',
        });
        return;
      }
      const link = document.createElement('a');
      link.setAttribute('download', filename);
      link.setAttribute('href', src);
      link.setAttribute('target', '_blank');
      link.dispatchEvent(new MouseEvent('click'));
      logEvent({
        data: {
          content_type: 'pdf',
          filename,
          module_id: currentModule.value?.slug,
          task_id: currentTask.value?.slug,
          url: src,
        },
        eventName: 'download_pdf',
      });
    }

    return { currentTask, handleClick, isTextTask, showModal, src };
  },
  render(): VNode | null {
    if (!isTextTask(this.currentTask?.slug)) return null;
    return (
      <div class="mt-6 flex flex-col items-center justify-center gap-6">
        <Characters class="w-[20rem]" />
        <NModal
          class="max-w-prose w-max"
          onClose={() => (this.showModal = false)}
          preset="card"
          show={this.showModal ?? false}
          title={this.$t('errorFileMissing')}
        >
          <p>{this.$t('errorTextFileMissing')}</p>
        </NModal>
        <NButton
          attrType="button"
          class="max-w-content mb-6"
          onClick={this.handleClick}
          round
          size="large"
          type="primary"
        >
          {this.$t('downloadTextAttachment')}
        </NButton>
      </div>
    );
  },
});
