import { type VNode } from 'vue';
import { NResult } from 'naive-ui';

export default defineNuxtComponent({
  name: 'LoadingLayer',
  props: { message: { default: '', type: String } },
  render(): VNode {
    return (
      <aside class="fixed inset-0 z-10 grid place-items-center bg-white">
        <NResult class="max-w-60 text-center">
          {{
            default: () => (
              <h1 class="text-md animate-heart-beat">
                {this.message || this.$t('loading')}
              </h1>
            ),
            icon: () => (
              <img
                alt="Kip crew"
                class="m-auto max-w-full w-full animate-pulse"
                src="/images/Kip-crew.png"
              />
            ),
          }}
        </NResult>
      </aside>
    );
  },
});
