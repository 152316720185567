import { type VNode } from 'vue';
import { NButton } from 'naive-ui';

export default defineNuxtComponent({
  name: 'ContentButtonCourseMenu',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getContentRef } = useContentStore();
    const localePath = useLocalePath();
    const isLastModule = computed<boolean>(() =>
      isLastModuleTask(route.params.id.toString())
    );
    async function handleClick(): Promise<void> {
      const courseId = getContentRef().course;
      if (!isLastModule.value && courseId) {
        await router.push(
          localePath({ name: 'course-id', params: { id: courseId } })
        );
      } else {
        await router.push(localePath({ name: 'trainer' }));
      }
    }
    return { handleClick, isLastModule };
  },
  render(): VNode {
    return (
      <div class="mt-2 text-center">
        <NButton
          class="mt-3"
          onClick={this.handleClick}
          round
          size="large"
          type="primary"
        >
          {this.isLastModule
            ? this.$t('toCourseMenu')
            : this.$t('toModuleMenu')}
        </NButton>
      </div>
    );
  },
});
