import {
  type Course,
  type UserGroup,
  type UserRole,
  type UserWithCustomClaims,
} from '@/types';
import { getAdminRole } from '@/utils';
import { useQuery } from '@tanstack/vue-query';

export default function useQueryData() {
  const currentUser = useCurrentUser();
  const { getCourses, getUserRoles, getUserGroups, getUserClaims } =
    useFirebase();

  const isEnabled = computed<boolean>(() => Boolean(currentUser.value));

  const {
    data: courses,
    error: errorCourses,
    isLoading: isLoadingCourses,
    suspense: suspenseCourses,
  } = useQuery<Course[]>({
    enabled: isEnabled,
    queryFn: getCourses,
    queryKey: [KEY_COURSES],
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const {
    data: userClaims,
    error: errorUserClaims,
    isLoading: isLoadingUserClaims,
    suspense: suspenseUserClaims,
  } = useQuery<UserWithCustomClaims['customClaims']>({
    enabled: isEnabled,
    queryFn: getUserClaims,
    queryKey: [KEY_USER_CLAIMS],
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const {
    data: userRoles,
    error: errorUserRoles,
    isLoading: isLoadingUserRoles,
    suspense: suspenseUserRoles,
  } = useQuery<UserRole[]>({
    enabled: isEnabled,
    queryFn: getUserRoles,
    queryKey: [KEY_USER_ROLES],
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const isAdmin = computed<boolean>(() => {
    if (!userRoles.value || !userClaims.value) return false;
    const adminRole = getAdminRole(userRoles.value);
    return Boolean(userClaims.value?.roles?.includes(String(adminRole?.id)));
  });

  const isEditor = computed<boolean>(() => {
    if (!userRoles.value || !userClaims.value) return false;
    const editorRole = userRoles.value?.find(
      (r): boolean => 'Trainer' === r.name
    );
    return (
      isAdmin.value ||
      Boolean(userClaims.value?.roles?.includes(String(editorRole?.id)))
    );
  });

  const {
    data: userGroups,
    error: errorUserGroups,
    isLoading: isLoadingUserGroups,
    suspense: suspenseUserGroups,
  } = useQuery<UserGroup[]>({
    enabled: isEnabled,
    queryFn: getUserGroups,
    queryKey: [KEY_USER_GROUPS],
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const {
    data: userRecords,
    error: errorUserRecords,
    isLoading: isLoadingUserRecords,
    suspense: suspenseUserRecords,
  } = useQuery<UserWithCustomClaims[]>({
    enabled: isEditor,
    queryKey: [KEY_USER_RECORDS],
    queryFn: async (): Promise<UserWithCustomClaims[]> =>
      $fetch<UserWithCustomClaims[]>('/api/users', {
        method: 'GET',
        query: { token: await currentUser.value?.getIdToken() },
      }),
    select: (users: UserWithCustomClaims[]): UserWithCustomClaims[] =>
      users.filter(
        (u: UserWithCustomClaims): boolean => u.uid !== currentUser.value?.uid
      ),
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  return {
    courses,
    errorCourses,
    errorUserClaims,
    errorUserGroups,
    errorUserRecords,
    errorUserRoles,
    isAdmin,
    isEditor,
    isEnabled,
    isLoadingCourses,
    isLoadingUserClaims,
    isLoadingUserGroups,
    isLoadingUserRecords,
    isLoadingUserRoles,
    suspenseCourses,
    suspenseUserClaims,
    suspenseUserGroups,
    suspenseUserRecords,
    suspenseUserRoles,
    userClaims,
    userGroups,
    userRecords,
    userRoles,
  };
}
