import { ClientOnly } from '#components';
import { NButton, NFormItem, NModal, NUpload } from 'naive-ui';
import {
  type UploadFileInfo,
  type UploadSettledFileInfo,
} from 'naive-ui/es/upload/src/public-types';
import { type VNode } from 'vue';

/**
 * Content upload and save component.
 * @link https://vueuse.org/core/useFileDialog
 */
export default defineNuxtComponent({
  name: 'ContentUploadAndSave',
  props: {
    accept: { default: 'image/png, image/jpeg', type: String },
    max: { default: '3', type: String },
    uploadLabel: { default: '', type: String },
    saveLabel: { default: '', type: String },
  },
  async setup() {
    const { $logger } = useNuxtApp();
    const { currentTask, getContentRef } = useContentStore();
    const {
      getUploadValue,
      markFileForDeletion,
      saveFilesToState,
      setHasMadeChanges,
    } = useDataStore();
    const defaultValue =
      (await getUploadValue(currentTask.value?.slug ?? '')) ?? [];
    const fileList = ref<UploadFileInfo[]>(defaultValue);

    const previewImageUrl = ref<string | null | undefined>(null);
    const showModal = ref<boolean>(false);

    function handlePreview({ url }: UploadSettledFileInfo): void {
      previewImageUrl.value = url;
      showModal.value = true;
    }

    /** Marks file for deletion. */
    function handleRemove({ file }: { file: UploadSettledFileInfo }): void {
      if (!file) return;
      markFileForDeletion(file, getContentRef());
      setHasMadeChanges(true);
    }

    /** Handles saving files to the store. */
    function handleUpdateFileList(files: UploadFileInfo[] | undefined): void {
      if (files?.length) $logger.debug('Processing files:', files);
      if (files) fileList.value = files;
      saveFilesToState(fileList.value, getContentRef());
      setHasMadeChanges(true);
    }

    watch(fileList, (): void =>
      $logger.info('File list updated:', fileList.value)
    );

    return {
      fileList,
      handlePreview,
      handleRemove,
      handleUpdateFileList,
      previewImageUrl,
      showModal,
    };
  },
  render(): VNode {
    return (
      <div class="w-full flex justify-between">
        <ClientOnly>
          <NFormItem
            class="flex-1"
            label={
              this.uploadLabel ?? this.$t('uploadLabel', { max: this.max })
            }
          >
            <NUpload
              accept={this.accept}
              defaultFileList={this.fileList}
              listType="image-card"
              max={parseInt(this.max)}
              onRemove={this.handleRemove}
              onPreview={this.handlePreview}
              onUpdate:fileList={this.handleUpdateFileList}
              showPreviewButton={true}
              showTrigger={true}
            />
          </NFormItem>
          <NModal
            class="max-w-prose w-max"
            onClose={() => (this.showModal = false)}
            preset="card"
            show={this.showModal ?? false}
            title={this.$t('preview')}
          >
            {this.previewImageUrl && (
              <img alt="" class="w-full" src={this.previewImageUrl} />
            )}
          </NModal>
          <NFormItem>
            <NButton attrType="submit" round size="large" type="success">
              {this.saveLabel || this.$t('saveLabel')}
            </NButton>
          </NFormItem>
        </ClientOnly>
      </div>
    );
  },
});
