import { NForm } from 'naive-ui';
import { type ComponentPublicInstance, type VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentForm',
  setup() {
    const { getContentRef } = useContentStore();
    const { saveEntryToFirestore } = useFirebase();
    const {
      hasMadeChanges,
      resetDeletedFiles,
      saveFilesToState,
      setEditorNotes,
      setHasMadeChanges,
      setTextarea,
      userProgress,
    } = useDataStore();
    const { removeTaskFiles, uploadFiles } = useFirebase();
    const { t } = useI18n();
    const { $logger } = useNuxtApp();

    onBeforeRouteLeave(() => {
      if (hasMadeChanges.value) {
        return window.confirm(t('confirmLeavePage'));
      }
    });

    const contentRef = getContentRef();
    const formElement = ref<
      ComponentPublicInstance<HTMLFormElement> | undefined
    >(undefined);
    const message = useMessage();

    async function handleSubmit(event: Event): Promise<void> {
      event.preventDefault();
      $logger.debug('Submit content event:', event);
      const form: HTMLFormElement = formElement.value?.$el as HTMLFormElement;
      const editorNotes: string =
        form.querySelector<HTMLTextAreaElement>('[name="editor-notes"]')
          ?.value ?? '';
      const userComment: string =
        form.querySelector<HTMLTextAreaElement>('[name="comment"]')?.value ??
        '';

      setEditorNotes(editorNotes, contentRef);
      setTextarea(userComment, contentRef);

      const entryIndex =
        userProgress.value?.findIndex((e) => e.task === contentRef.task) ?? -1;
      if (-1 === entryIndex || !userProgress.value) {
        $logger.warn(
          'Something might be wrong. Unable to determine index for:',
          contentRef
        );
        return;
      }

      // Remove files
      const filesToBeDeleted =
        userProgress.value[entryIndex]?.filesToBeDeleted ?? [];
      await removeTaskFiles(contentRef, filesToBeDeleted);
      resetDeletedFiles(contentRef);

      // Save progress to database
      await saveEntryToFirestore({
        app: 'campus',
        ...Object.fromEntries(
          Object.entries(userProgress.value[entryIndex]).filter(
            (entry) => 'files' !== entry[0]
          )
        ),
        filesToBeDeleted: [],
        timestamp: new Date().toISOString(),
      });

      // Upload new files
      const files = await uploadFiles(
        userProgress.value[entryIndex].files ?? [],
        contentRef
      );
      saveFilesToState(files, contentRef);
      setHasMadeChanges(false);
      message.success(t('successSave'));
    }

    onMounted(() => {
      setHasMadeChanges(false);
    });

    return { formElement, handleSubmit };
  },
  render(): VNode {
    return (
      <NForm onSubmit={this.handleSubmit} ref="formElement">
        {this.$slots.default?.()}
      </NForm>
    );
  },
});
