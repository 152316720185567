
// @ts-nocheck
import locale__Users_niko_Developer_FunAcademy_fun_academy_campus_src_locales_en_json from "../src/locales/en.json";
import locale__Users_niko_Developer_FunAcademy_fun_academy_campus_src_locales_fi_json from "../src/locales/fi.json";
import locale__Users_niko_Developer_FunAcademy_fun_academy_campus_src_locales_vi_json from "../src/locales/vi.json";


export const localeCodes =  [
  "en",
  "fi",
  "vi"
]

export const localeLoaders = {
  "en": [{ key: "../src/locales/en.json", load: () => Promise.resolve(locale__Users_niko_Developer_FunAcademy_fun_academy_campus_src_locales_en_json), cache: true }],
  "fi": [{ key: "../src/locales/fi.json", load: () => Promise.resolve(locale__Users_niko_Developer_FunAcademy_fun_academy_campus_src_locales_fi_json), cache: true }],
  "vi": [{ key: "../src/locales/vi.json", load: () => Promise.resolve(locale__Users_niko_Developer_FunAcademy_fun_academy_campus_src_locales_vi_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "dir": "ltr",
      "isCatchallLocale": true,
      "iso": "en-US",
      "name": "English",
      "files": [
        "locales/en.json"
      ]
    },
    {
      "code": "fi",
      "dir": "ltr",
      "iso": "fi-FI",
      "name": "Suomi",
      "files": [
        "locales/fi.json"
      ]
    },
    {
      "code": "vi",
      "dir": "ltr",
      "iso": "vi-VN",
      "name": "Tiếng Việt",
      "files": [
        "locales/vi.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "en",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "sign-in/index": {
      "fi": "/kirjaudu"
    },
    "course/[id]": {
      "fi": "/kurssi/[id]"
    },
    "module/[id]": {
      "fi": "/moduuli/[id]"
    },
    "parent/index": {
      "fi": "/huoltaja"
    },
    "register/index": {
      "fi": "/luo-tili"
    },
    "sign-out": {
      "fi": "/kirjaudu-ulos"
    },
    "task/[id]": {
      "fi": "/tehtava/[id]"
    },
    "trainer/index": {
      "fi": "/valmentaja"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "dir": "ltr",
    "isCatchallLocale": true,
    "iso": "en-US",
    "name": "English",
    "files": [
      {
        "path": "locales/en.json"
      }
    ]
  },
  {
    "code": "fi",
    "dir": "ltr",
    "iso": "fi-FI",
    "name": "Suomi",
    "files": [
      {
        "path": "locales/fi.json"
      }
    ]
  },
  {
    "code": "vi",
    "dir": "ltr",
    "iso": "vi-VN",
    "name": "Tiếng Việt",
    "files": [
      {
        "path": "locales/vi.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
