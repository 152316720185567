import { createDiscreteApi, type GlobalThemeOverrides } from 'naive-ui';

export default function useTheme() {
  const themeOverrides: GlobalThemeOverrides = {
    Card: {
      titleTextColor: '#da0063',
    },
    common: {
      borderRadius: '0.5rem',
      fontFamily: 'Open Sans, sans-serif',
      primaryColor: '#008996',
      primaryColorHover: '#006d7a',
      primaryColorSuppl: '#737373',
      successColor: '#75D1C8',
      textColorDisabled: '#737373',
    },
    Dialog: {
      iconColorInfo: '#008996',
      titleTextColor: '#da0063',
    },
    Input: {
      borderFocus: '1px solid #020404',
      borderHover: '1px solid #008996',
    },
  };

  const { dialog, loadingBar, message, notification } = createDiscreteApi(
    ['dialog', 'loadingBar', 'message', 'notification'],
    { configProviderProps: { themeOverrides } }
  );

  return {
    dialog,
    loadingBar,
    message,
    notification,
    themeOverrides,
  };
}
