import { ClientOnly } from '#components';
import { NButton, NCard } from 'naive-ui';
import { type PDFDocument, type PDFForm } from 'pdf-lib';
import { type VNode } from 'vue';

interface PDFData {
  courseName: string;
  studentName1: string;
  summaryImage1: string;
  summaryImage2: string;
  summaryImage3: string;
  summaryNotes: string;
  summaryNoteTitle: string;
  summaryTitle: string;
  summaryDescription: string;
  task1Answer: string;
  task1AnswerTitle: string;
  task2AnswerTitle: string;
  task3AnswerTitle: string;
  task1Image1: string;
  task1Image2: string;
  task1Image3: string;
  task1Question: string;
  task1Title: string;
  task2Answer: string;
  task2Image1: string;
  task2Image2: string;
  task2Image3: string;
  task2Question: string;
  task2Title: string;
  task3Answer: string;
  task3Image1: string;
  task3Image2: string;
  task3Image3: string;
  task3Question: string;
  task3Title: string;
}

export default defineNuxtComponent({
  name: 'ContentDownloadPortfolio',
  async setup() {
    const {
      getDescription,
      getPdfPlaceholderImageUrl,
      getPdfUrlFromContent,
      getTitle,
      portfolioSlug,
      summarySlug,
      textTaskSlug,
      videoTaskSlug,
      webinarTaskSlug,
    } = useContentStore();
    const currentUser = useCurrentUser();
    const { getImageUrl, getTextareaValue, setModuleProgress } = useDataStore();
    const { currentCourse, currentModule, currentTask, getContentRef } =
      useContentStore();
    const { getModuleProgress, getModuleFiles } = useFirebase();
    const { t } = useI18n();
    const { $logger } = useNuxtApp();
    const { getFont, getPDFImage, getFontSizeTextField, downloadPDF } =
      usePDF();

    const contentRef = getContentRef();
    const placeholderImage =
      (await getPdfPlaceholderImageUrl(portfolioSlug.value)) ||
      '/portfolio.png';
    const isLoading = ref(false);

    const moduleProgress = await getModuleProgress(contentRef, 'task');
    const moduleFiles = await getModuleFiles(contentRef);

    setModuleProgress(moduleProgress, moduleFiles, contentRef.module);

    const pdfData = computed<Promise<PDFData>>(async () => ({
      courseName: currentCourse.value?.title ?? '',
      studentName1: currentUser.value?.displayName ?? '',
      summaryImage1: getImageUrl(summarySlug.value, 0),
      summaryImage2: getImageUrl(summarySlug.value, 1),
      summaryImage3: getImageUrl(summarySlug.value, 2),
      summaryNotes: getTextareaValue(summarySlug.value),
      summaryNoteTitle: t('summaryNoteTitle'),
      summaryTitle: await getTitle(summarySlug.value),
      summaryDescription: await getDescription(summarySlug.value),
      task1Answer: getTextareaValue(videoTaskSlug.value),
      task1AnswerTitle: t('task1AnswerTitle'),
      task2AnswerTitle: t('task2AnswerTitle'),
      task3AnswerTitle: t('task3AnswerTitle'),
      task1Image1: getImageUrl(videoTaskSlug.value, 0),
      task1Image2: getImageUrl(videoTaskSlug.value, 1),
      task1Image3: getImageUrl(videoTaskSlug.value, 2),
      task1Question: await getDescription(videoTaskSlug.value),
      task1Title: await getTitle(videoTaskSlug.value),
      task2Answer: getTextareaValue(textTaskSlug.value),
      task2Image1: getImageUrl(textTaskSlug.value, 0),
      task2Image2: getImageUrl(textTaskSlug.value, 1),
      task2Image3: getImageUrl(textTaskSlug.value, 2),
      task2Question: await getDescription(textTaskSlug.value),
      task2Title: await getTitle(textTaskSlug.value),
      task3Answer: getTextareaValue(webinarTaskSlug.value),
      task3Image1: getImageUrl(webinarTaskSlug.value, 0),
      task3Image2: getImageUrl(webinarTaskSlug.value, 1),
      task3Image3: getImageUrl(webinarTaskSlug.value, 2),
      task3Question: await getDescription(webinarTaskSlug.value),
      task3Title: await getTitle(webinarTaskSlug.value),
    }));

    function getQuestionFontSize(title: string): number {
      return 350 > title.length ? 11 : 10;
    }

    async function fillFields(
      form: PDFForm,
      pdfDocument: PDFDocument
    ): Promise<void> {
      const montserratRegular = await pdfDocument.embedFont(
        await getFont('/fonts/Montserrat-Regular.ttf')
      );
      const montserratBold = await pdfDocument.embedFont(
        await getFont('/fonts/Montserrat-Bold.ttf')
      );
      const generatedDate = new Date();
      const studentName = (await pdfData.value).studentName1;
      const courseNameText = (await pdfData.value).courseName;
      const courseName = form.getTextField('courseName');
      courseName.setText(courseNameText);
      courseName.updateAppearances(montserratRegular);

      // General
      pdfDocument.setTitle(courseNameText);
      pdfDocument.setAuthor(studentName);
      pdfDocument.setSubject(t('portfolio'));
      pdfDocument.setKeywords([
        'fun academy',
        'campus',
        t('portfolio').toLowerCase(),
      ]);
      pdfDocument.setProducer(t('producer'));
      pdfDocument.setCreator(t('creator'));
      pdfDocument.setCreationDate(generatedDate);
      pdfDocument.setModificationDate(generatedDate);

      const studentNameFirstPage = form.getTextField('studentName1');
      studentNameFirstPage.setText(studentName);
      studentNameFirstPage.updateAppearances(montserratRegular);

      // Video task
      const task1Title = form.getTextField('task1Title');
      const task1TitleText = (await pdfData.value).task1Title;
      task1Title.setText(task1TitleText);
      task1Title.updateAppearances(montserratBold);

      const task1Question = form.getTextField('task1Question');
      const task1QuestionText = (await pdfData.value).task1Question;
      task1Question.setFontSize(getQuestionFontSize(task1QuestionText));
      task1Question.setText(task1QuestionText);
      task1Question.updateAppearances(montserratRegular);

      const task1AnswerTitle = form.getTextField('task1AnswerTitle');
      const task1AnswerTitleText = (await pdfData.value).task1AnswerTitle;
      task1AnswerTitle.setText(task1AnswerTitleText);
      task1AnswerTitle.updateAppearances(montserratBold);

      const task1Answer = form.getTextField('task1Answer');
      const task1AnswerText = (await pdfData.value).task1Answer;
      task1Answer.enableMultiline();
      task1Answer.setText(task1AnswerText);
      task1Answer.setFontSize(getFontSizeTextField(task1AnswerText));
      task1Answer.updateAppearances(montserratRegular);

      const task1Image1 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task1Image1
      );
      if (task1Image1) form.getButton('task1Image1').setImage(task1Image1);

      const task1Image2 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task1Image2
      );
      if (task1Image2) form.getButton('task1Image2').setImage(task1Image2);

      const task1Image3 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task1Image3
      );
      if (task1Image3) form.getButton('task1Image3').setImage(task1Image3);

      // Text task
      const task2Title = form.getTextField('task2Title');
      task2Title.setText((await pdfData.value).task2Title);
      task2Title.updateAppearances(montserratBold);

      const task2Question = form.getTextField('task2Question');
      const task2QuestionText = (await pdfData.value).task2Question;
      task2Question.setText(task2QuestionText);
      task2Question.setFontSize(getQuestionFontSize(task2QuestionText));
      task2Question.updateAppearances(montserratRegular);

      const task2AnswerTitle = form.getTextField('task2AnswerTitle');
      task2AnswerTitle.setText((await pdfData.value).task2AnswerTitle);
      task2AnswerTitle.updateAppearances(montserratBold);

      const task2Answer = form.getTextField('task2Answer');
      const task2AnswerText = (await pdfData.value).task2Answer;
      task2Answer.enableMultiline();
      task2Answer.setFontSize(getFontSizeTextField(task2AnswerText));
      task2Answer.setText(task2AnswerText);
      task2Answer.updateAppearances(montserratRegular);

      const task2Image1 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task2Image1
      );
      if (task2Image1) form.getButton('task2Image1').setImage(task2Image1);

      const task2Image2 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task2Image2
      );
      if (task2Image2) form.getButton('task2Image2').setImage(task2Image2);

      const task2Image3 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task2Image3
      );
      if (task2Image3) form.getButton('task2Image3').setImage(task2Image3);

      // Webinar
      const task3Title = form.getTextField('task3Title');
      task3Title.setText((await pdfData.value).task3Title);
      task3Title.updateAppearances(montserratBold);

      const task3Question = form.getTextField('task3Question');
      const task3QuestionText = (await pdfData.value).task3Question;
      task3Question.setFontSize(getQuestionFontSize(task3QuestionText));
      task3Question.setText(task3QuestionText);
      task3Question.updateAppearances(montserratRegular);

      const task3AnswerTitle = form.getTextField('task3AnswerTitle');
      task3AnswerTitle.setText((await pdfData.value).task3AnswerTitle);
      task3AnswerTitle.updateAppearances(montserratBold);

      const task3Answer = form.getTextField('task3Answer');
      const task3AnswerText = (await pdfData.value).task3Answer;
      task3Answer.enableMultiline();
      task3Answer.setFontSize(getFontSizeTextField(task3AnswerText));
      task3Answer.setText(task3AnswerText);
      task3Answer.updateAppearances(montserratRegular);

      const task3Image1 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task3Image1
      );
      if (task3Image1) form.getButton('task3Image1').setImage(task3Image1);

      const task3Image2 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task3Image2
      );
      if (task3Image2) form.getButton('task3Image2').setImage(task3Image2);

      const task3Image3 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).task3Image3
      );
      if (task3Image3) form.getButton('task3Image3').setImage(task3Image3);

      // Summary
      const summaryNoteTitle = form.getTextField('summaryNoteTitle');
      summaryNoteTitle.setText((await pdfData.value).summaryNoteTitle);
      summaryNoteTitle.updateAppearances(montserratBold);

      const summaryNotes = form.getTextField('summaryNotes');
      const summaryNotesText = (await pdfData.value).summaryNotes;
      summaryNotes.enableMultiline();
      summaryNotes.setText(summaryNotesText);
      summaryNotes.setFontSize(getFontSizeTextField(summaryNotesText));
      summaryNotes.updateAppearances(montserratRegular);

      const summaryTitle = form.getTextField('summaryTitle');
      summaryTitle.setText((await pdfData.value).summaryTitle);
      summaryTitle.updateAppearances(montserratBold);

      const summaryDescription = form.getTextField('summaryDescription');
      summaryDescription.setText((await pdfData.value).summaryDescription);
      summaryDescription.updateAppearances(montserratRegular);

      const summaryImage1 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).summaryImage1
      );
      if (summaryImage1) {
        form.getButton('summaryImage1').setImage(summaryImage1);
      }

      const summaryImage2 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).summaryImage2
      );
      if (summaryImage2) {
        form.getButton('summaryImage2').setImage(summaryImage2);
      }

      const summaryImage3 = await getPDFImage(
        pdfDocument,
        (await pdfData.value).summaryImage3
      );
      if (summaryImage3) {
        form.getButton('summaryImage3').setImage(summaryImage3);
      }

      form.flatten();
    }

    /** Handles the form submit. */
    async function handleSubmit(event: Event): Promise<void> {
      event.preventDefault();
      const pdfUrl = await getPdfUrlFromContent(portfolioSlug.value);
      if (!pdfUrl) {
        $logger.error('Diploma missing:', portfolioSlug.value);
        return;
      }
      isLoading.value = true;
      await downloadPDF({
        fillCallback: fillFields,
        name: `module-${currentModule.value?.slug}`,
        moduleId: currentModule.value?.slug,
        pdfUrl,
        taskId: currentTask.value?.slug,
      });
      isLoading.value = false;
    }

    return {
      currentTask,
      handleSubmit,
      isLastTask,
      isLoading,
      placeholderImage,
    };
  },
  render(): VNode | null {
    if (!this.isLastTask(this.currentTask?.slug)) return null;
    return (
      <div class="text-center">
        <ClientOnly>
          <NCard class="mx-auto my-6 max-w-80 !block !w-full">
            <a
              href="/portfolio.pdf"
              onClick={this.handleSubmit}
              target="_blank"
            >
              <img
                alt={this.$t('portfolio')}
                class="pdf-cover w-full"
                src={this.placeholderImage}
              />
            </a>
          </NCard>
          <NButton
            class="mb-3"
            disabled={this.isLoading}
            loading={this.isLoading}
            onClick={this.handleSubmit}
            round
            size="large"
            type="success"
          >
            {this.$t('downloadPortfolio')}
          </NButton>
        </ClientOnly>
      </div>
    );
  },
});
