import { type VNode } from 'vue';

export default defineNuxtComponent({
  name: 'SideImage',
  setup() {
    const { currentCourse, currentModule, currentTask } = useContentStore();
    const route = useRoute();
    const alt = computed<null | string | undefined>(() =>
      currentTask.value && isTask(String(route.params.id))
        ? currentTask.value?.sideImage?.alt
        : currentModule.value && isModule(String(route.params.id))
          ? currentModule.value?.sideImage?.alt
          : currentCourse.value && isCourse(String(route.params.id))
            ? currentCourse.value?.sideImage?.src
            : null
    );
    const src = computed<null | string | undefined>(() =>
      currentTask.value && isTask(String(route.params.id))
        ? currentTask.value?.sideImage?.src
        : currentModule.value && isModule(String(route.params.id))
          ? currentModule.value?.sideImage?.src
          : currentCourse.value && isCourse(String(route.params.id))
            ? currentCourse.value?.sideImage?.src
            : null
    );
    return { alt, src };
  },
  render(): VNode | null {
    if (!this.src) return null;
    return (
      <figure class="mb-4 ml-auto mr-0 mt-0 border-1 border-gray-300 border-solid p-2 text-center xs:float-right xs:ml-4">
        <img alt={this.alt ?? ''} class="m-auto h-auto w-36" src={this.src} />
      </figure>
    );
  },
});
