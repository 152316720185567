import { setup } from '@css-render/vue3-ssr';
import { type HeadTag } from '@unhead/schema';

/**
 * Naive UI for Nuxt.
 * @link https://github.com/07akioni/naive-ui-nuxt-demo
 */
export default defineNuxtPlugin({
  name: 'naive-ui',
  enforce: 'pre',
  setup(nuxtApp) {
    if (import.meta.server) {
      const { collect } = setup(nuxtApp.vueApp);
      nuxtApp.ssrContext?.head.hooks.hook('tags:resolve', (ctx) => {
        const lastMetaIndex = ctx.tags.map((x) => x.tag).lastIndexOf('meta');
        const styleTags: HeadTag[] = collect()
          .split('</style>')
          .filter(Boolean)
          .map((x) => {
            const id = x.match(/cssr-id="(.+?)"/)?.[1];
            const style = (x.match(/>(.*)/s)?.[1] ?? '').trim();
            return {
              tag: 'style',
              props: { 'cssr-id': id },
              innerHTML: style,
            };
          }) as HeadTag[];
        ctx.tags.splice(lastMetaIndex + 1, 0, ...styleTags);
      });
    }
  },
});
