import { NButton, NIcon } from 'naive-ui';
import { type Type } from 'naive-ui/es/button/src/interface';
import { type PropType, type VNode } from 'vue';
import ChevronLeft from '@/assets/icons/chevron-left.svg?component';

export default defineNuxtComponent({
  name: 'ContentBackButton',
  props: {
    to: { default: undefined, type: String },
    label: { default: undefined, type: String },
    preview: { default: undefined, type: String },
    type: { default: 'tertiary', type: String as PropType<Type> },
  },
  setup(props) {
    const { $logger } = useNuxtApp();
    const localePath = useLocalePath();
    async function handleClick(): Promise<void> {
      const to = localePath({
        name: props.to,
        query: { preview: props.preview },
      });
      $logger.debug('Continuing to:', to);
      await navigateTo(to);
    }
    return { handleClick };
  },
  render(): VNode | null {
    if (!this.to) return null;
    return (
      <div class="my-8 text-left">
        <NButton
          attrType="button"
          iconPlacement="left"
          renderIcon={() => h(NIcon, null, { default: () => h(ChevronLeft) })}
          onClick={this.handleClick}
          round
          size="large"
          type="tertiary"
        >
          {this.label}
        </NButton>
      </div>
    );
  },
});
