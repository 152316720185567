import { NButton } from 'naive-ui';
import { type Type } from 'naive-ui/es/button/src/interface';
import { type PropType, type VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentButtonNext',
  props: {
    paramsId: { default: '', type: String },
    to: { type: String, required: true },
    type: { default: 'primary', type: String as PropType<Type> },
  },
  setup(props) {
    const { $logger } = useNuxtApp();
    const localePath = useLocalePath();
    async function handleClick(): Promise<void> {
      const to = localePath({ name: props.to, params: { id: props.paramsId } });
      $logger.debug('Continuing to:', to);
      await navigateTo(to);
    }
    return { handleClick };
  },
  render(): VNode {
    return (
      <div class="my-12 text-center">
        <NButton
          attrType="button"
          onClick={this.handleClick}
          round
          size="large"
          type={this.type}
        >
          {this.$slots.default?.()}
        </NButton>
      </div>
    );
  },
});
