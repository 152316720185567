import { type VNode } from 'vue';

export default defineNuxtComponent({
  name: 'ContentDisclaimer',
  render(): VNode {
    return (
      <aside class="mt-4">
        <small>{this.$slots.default?.()}</small>
      </aside>
    );
  },
});
