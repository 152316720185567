export const AUTH_ROUTES = [
  'auth',
  'new-password',
  'register',
  'reset-password',
  'sign-in',
  'sign-out',
];

export const DEFAULT_LOCALE_ISO = 'en-US';

export const DEFAULT_CONTENT_LOCALE = 'en';

export const KEY_USER_CLAIMS = 'userClaims';

export const KEY_COURSES = 'courses';

export const KEY_USER_GROUPS = 'userGroups';

export const KEY_USER_RECORDS = 'userRecords';

export const KEY_USER_ROLES = 'userRoles';

export const MESSAGE_DURATION = 5000;
