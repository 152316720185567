import { NFormItem, NInput } from 'naive-ui';
import { type VNode } from 'vue';
import { ClientOnly } from '#components';

export default defineNuxtComponent({
  name: 'ContentTextarea',
  props: {
    label: { default: '', type: String },
    max: { default: 1600, type: Number },
    placeholder: { default: '', type: String },
  },
  setup() {
    const { currentTask, getContentRef } = useContentStore();
    const currentUser = useCurrentUser();
    const { getTextareaValue, setTextarea } = useDataStore();
    const { isEditor } = useQueryData();
    const { selectedUser } = useUserManagement();

    const fieldValue = computed<string>({
      get: () => {
        return getTextareaValue(currentTask.value?.slug ?? '') || '';
      },
      set: (value: string) => setTextarea(value, getContentRef()),
    });

    const isDisabled = computed<boolean>(
      () =>
        isEditor.value &&
        !!selectedUser.value &&
        currentUser.value?.uid !== selectedUser.value.value
    );

    return {
      currentUser,
      fieldValue,
      isDisabled,
      isEditor,
      selectedUser,
    };
  },
  render(): VNode {
    return (
      <ClientOnly>
        <NFormItem
          class="mt-8"
          label={
            this.label ||
            (this.isEditor &&
            !!this.selectedUser &&
            this.selectedUser?.value !== this.currentUser?.uid
              ? this.$t('responseLabelStudent')
              : this.$t('responseLabel'))
          }
        >
          <NInput
            autosize={{ minRows: 6, maxRows: 20 }}
            disabled={this.isDisabled}
            inputProps={{ name: 'comment' }}
            maxlength={this.max}
            placeholder={
              !this.fieldValue
                ? this.placeholder ||
                  this.$t('textareaPlaceholder', { max: this.max })
                : undefined
            }
            show-count
            type="textarea"
            v-model:value={this.fieldValue}
          />
        </NFormItem>
      </ClientOnly>
    );
  },
});
