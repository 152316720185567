import { type ContentReference, type ParsedContent } from '@/types';

interface ContentStoreState {
  availableModulesCount: number;
  content: ParsedContent | null;
  currentCourse: ParsedContent | null;
  currentModule: ParsedContent | null;
  currentTask: ParsedContent | null;
  hasFetchedContent: boolean;
  isAuthorizedToViewContent: boolean;
}

/**
 * Contains a basic store for general information about the app.
 *
 * @example Getting values
 * ```ts
 * const contentStore = useContentStore();
 * const { currentTask, currentCourse, currentModule } = contentStore;
 * console.log('Current course:', currentCourse.value); // '1'
 * console.log('Current module:', currentModule.value);// '1.1'
 * console.log('Current task:', currentTask.value); // '1.1.1'
 * ```
 * @example Setting values
 * ```ts
 * const contentStore = useContentStore();
 * contentStore.setCurrentCourse('1');
 * contentStore.setCurrentModule('1.1');
 * contentStore.setCurrentTask('1.1.1');
 * ```
 * @package fun-academy-campus
 */
export default function useContentStore() {
  const { $logger } = useNuxtApp();
  const state = useState<ContentStoreState>('contentStore', () => ({
    availableModulesCount: 0,
    content: null,
    currentCourse: null,
    currentModule: null,
    currentTask: null,
    hasFetchedContent: false,
    isAuthorizedToViewContent: false,
  }));
  const availableModulesCount = computed<
    ContentStoreState['availableModulesCount']
  >(() => state.value.availableModulesCount);
  const content = computed<ContentStoreState['content']>(
    () => state.value.content
  );
  const currentCourse = computed<ContentStoreState['currentCourse']>(
    () => state.value.currentCourse
  );
  const currentModule = computed<ContentStoreState['currentCourse']>(
    () => state.value.currentModule
  );
  const currentTask = computed<ContentStoreState['currentCourse']>(
    () => state.value.currentTask
  );
  const hasFetchedContent = computed<ContentStoreState['hasFetchedContent']>(
    () => state.value.hasFetchedContent
  );
  const isAuthorizedToViewContent = computed<
    ContentStoreState['isAuthorizedToViewContent']
  >(() => state.value.isAuthorizedToViewContent);
  const portfolioSlug = computed<string>(
    () => `${state.value.currentModule?.slug}.5`
  );
  const summarySlug = computed<string>(
    () => `${state.value.currentModule?.slug}.4`
  );
  const textTaskSlug = computed<string>(
    () => `${state.value.currentModule?.slug}.2`
  );
  const videoTaskSlug = computed<string>(
    () => `${state.value.currentModule?.slug}.1`
  );
  const webinarTaskSlug = computed<string>(
    () => `${state.value.currentModule?.slug}.3`
  );

  /** Creates a content reference based on a task slug. */
  function createContentRefBasedOnTask(taskSlug?: string): ContentReference {
    if (!taskSlug || !isTask(taskSlug)) {
      throw new Error(`Incorrect task format: ${taskSlug}`);
    }
    const pieces = taskSlug.split('.');
    return {
      course: pieces[0],
      module: `${pieces[0]}.${pieces[1]}`,
      task: taskSlug,
    };
  }

  /** Gets the current content reference incl. course, module and task. */
  function getContentRef(): ContentReference {
    return {
      course: state?.value?.currentCourse?.slug ?? '',
      module: state?.value?.currentModule?.slug ?? '',
      task: state?.value?.currentTask?.slug ?? '',
    };
  }

  /** Gets the description field from a content document based on a slug. */
  async function getDescription(slug: string): Promise<string> {
    const content = (await queryContent()
      .where({ slug })
      .findOne()) as ParsedContent;
    return content?.description ?? '';
  }

  /** Gets the PDF placeholder image URL from a content document based on a slug. */
  async function getPdfPlaceholderImageUrl(slug: string): Promise<string> {
    const content = (await queryContent()
      .where({ slug })
      .findOne()) as ParsedContent;
    return getFirebaseStorageUrl(content.pdfPlaceholderImageUrl);
  }

  /** Gets the PDF URL from a content document based on a slug. */
  async function getPdfUrlFromContent(slug: string): Promise<string> {
    const content = (await queryContent()
      .where({ slug })
      .findOne()) as ParsedContent;
    return getFirebaseStorageUrl(content.pdfTemplateUrl);
  }

  /** Gets the title from a content document based on a slug. */
  async function getTitle(slug: string): Promise<string> {
    const content = (await queryContent()
      .where({ slug })
      .findOne()) as ParsedContent;
    return content?.title ?? '';
  }

  /** Sets the current content for Markdown pages. */
  function setContent(content: ParsedContent | null): void {
    state.value.content = content ?? null;
  }

  /** Sets the amount of available modules. */
  function setAvailableModulesCount(number: number): void {
    $logger.debug('Setting available modules:', number);
    state.value.availableModulesCount = number;
  }

  /** Sets the current course. */
  async function setCurrentCourse(
    slug: string,
    localeCode: string
  ): Promise<void> {
    try {
      state.value.currentCourse = slug
        ? ((await queryContent()
            .where({ _locale: localeCode, slug })
            .findOne()) as ParsedContent)
        : null;
    } catch (error) {
      $logger.error('Unable to set current course:', {
        error,
        localeCode,
        slug,
      });
    }
  }

  /** Sets the current module. */
  async function setCurrentModule(
    slug: string,
    localeCode: string
  ): Promise<void> {
    try {
      state.value.currentModule = slug
        ? ((await queryContent()
            .where({ slug, _locale: localeCode })
            .findOne()) as ParsedContent)
        : null;
    } catch (error) {
      $logger.error('Unable to set current module:', {
        error,
        localeCode,
        slug,
      });
    }
  }

  /** Sets the current content. */
  async function setCurrentTask(
    slug: string,
    localeCode: string
  ): Promise<void> {
    try {
      state.value.currentTask = slug
        ? ((await queryContent()
            .where({ slug, _locale: localeCode })
            .findOne()) as ParsedContent)
        : null;
    } catch (error) {
      $logger.error('Unable to set current content:', {
        error,
        localeCode,
        slug,
      });
    }
  }

  /** Sets the has fetched content state. */
  function setHasFetchedContent(value: boolean): void {
    $logger.debug('Setting has fetched content:', value);
    state.value.hasFetchedContent = Boolean(value);
  }

  /** Sets the is authorized to view content state. */
  function setIsAuthorizedToViewContent(value: boolean): void {
    $logger.debug('Setting is authorized to view content:', value);
    state.value.isAuthorizedToViewContent = Boolean(value);
  }

  return {
    availableModulesCount,
    content,
    createContentRefBasedOnTask,
    currentCourse,
    currentModule,
    currentTask,
    getContentRef,
    getDescription,
    getPdfPlaceholderImageUrl,
    getPdfUrlFromContent,
    getTitle,
    hasFetchedContent,
    isAuthorizedToViewContent,
    portfolioSlug,
    setAvailableModulesCount,
    setContent,
    setCurrentCourse,
    setCurrentModule,
    setCurrentTask,
    setHasFetchedContent,
    setIsAuthorizedToViewContent,
    summarySlug,
    textTaskSlug,
    videoTaskSlug,
    webinarTaskSlug,
  };
}
