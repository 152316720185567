import chalk from 'chalk';
import consola, { type ConsolaInstance } from 'consola';

declare module '#app' {
  interface NuxtApp {
    $logger: ConsolaInstance;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const { consolaLevel } = useRuntimeConfig().public;
  const level = parseInt(consolaLevel);
  const logger = consola.create({ defaults: { tag: 'campus' }, level });
  logger.success('Consola logger initialized using level:', chalk.cyan(level));
  nuxtApp.provide('logger', logger);
});
