import validate from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/pages/runtime/validate.js";
import content_45global from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/middleware/content.global.ts";
import fetch_45global from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/middleware/fetch.global.ts";
import manifest_45route_45rule from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@9.8.0_ioredis@5.4_ifvzlqncwydybef7477fhbfoty/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  content_45global,
  fetch_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-client": () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/middleware/admin.client.ts"),
  auth: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/middleware/auth.ts"),
  "editor-client": () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/middleware/editor.client.ts")
}