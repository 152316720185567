import { type ContentType } from '@/types';
import { type PropType, type VNode } from 'vue';
import { getFirebaseStorageUrl } from '@/utils';

export default defineNuxtComponent({
  name: 'ContentVideo',
  props: {
    type: {
      type: String as PropType<ContentType>,
    },
  },
  async setup() {
    const { currentModule, currentTask } = useContentStore();
    const { logEvent } = useFirebase();
    const src = await getFirebaseStorageUrl(currentTask.value?.videoUrl);

    function handlePlayEvent(): void {
      logEvent({
        data: {
          filename: new URL(src).pathname.split('/').pop(),
          module_id: currentModule.value?.slug,
          task_id: currentTask.value?.slug,
        },
        eventName: 'play_video',
      });
    }

    return { handlePlayEvent, src };
  },
  render(): VNode | null {
    if (!this.src) return null;
    return (
      <>
        {'webinar-task' === this.type ? (
          <p>{this.$t('webinarVideoDescription')}</p>
        ) : null}
        <div
          class="embed-responsive embed-responsive-16by9 relative my-6 w-full overflow-hidden"
          style="padding-top: 56%"
        >
          <iframe
            allowfullscreen
            class="embed-responsive-item absolute bottom-0 left-0 right-0 top-0 h-full w-full border-0"
            onPlay={this.handlePlayEvent}
            src={this.src}
            title={this.$t('video')}
          />
        </div>
      </>
    );
  },
});
